import { ref } from 'vue';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { getClients } from '@/api/clients';
import { useFetch } from '@/api';
import { useSettings } from '@/composables/useSettings';
import { parseSeparatedNumber } from '@/utils/numberFormatter';

dayjs.extend(customParseFormat);

const clients = ref([]);
export const subscriptionApi = () => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const isLoading = ref(false);
  const { numberFormat } = useSettings();

  const addSubscription = async (subscription) => {
    dayjs.extend(utc);
    const { dateFormat } = useSettings();

    isLoading.value = true;
    const {
      data, isSuccess, errorObject,
    } = await axiosWrapper({
      type: 'post',
      url: 'merchants/clients/subscription',
      params: {
        ...subscription,
        startDate: dayjs.utc(subscription.startDate, dateFormat.value).format(),
        amount: parseSeparatedNumber(subscription.amount, numberFormat.value),
      },
    });
    isLoading.value = false;

    if (isSuccess) {
      successHandler(t('merchantSubscriptions.notification.addSubscriptionSuccess'));
    }
    return { data, isSuccess, errorObject };
  };

  const onGetClients = async (search) => {
    const { isSuccess, data } = await getClients({ isFreshResponse: true, shouldResetRequestState: true, search });
    if (isSuccess) {
      clients.value = data;
    }

    return { data };
  };

  return {
    isLoading,
    addSubscription,
    clients,
    onGetClients,
  };
};

export const cancelSubscription = async ({ subscriptionId }) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: `/merchants/clients/subscription/${subscriptionId}/cancel`,
  });

  if (isSuccess) {
    successHandler(t('merchantSubscriptions.notification.cancelSubscriptionSuccess'));
  }

  return { data, isSuccess };
};

export const getPaymentsForSubscription = async ({ subscriptionId, isFreshResponse = false }) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: `/merchants/payments/subscription/${subscriptionId}`,
    params: { isFreshResponse },
  });

  return { data, isSuccess };
};
