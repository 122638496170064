import { reactive, ref } from 'vue';

import { TABLE_TYPES, statusCellOptions } from '@/common/tableTypes';

export const columns = [
  {
    name: 'clientSubscriptionId',
    isShow: true,
    isPermanent: true,
    type: TABLE_TYPES.ID,
    width: 180,
    suppressSizeToFit: true,
  },
  {
    name: 'title',
    isShow: true,
    isPermanent: true,
    width: 280,
    suppressSizeToFit: true,
  },
  {
    name: 'clientName',
    type: TABLE_TYPES.PERSON,
    isShow: true,
    width: 280,
  },
  {
    name: 'clientEmail',
    isShow: true,
    isPermanent: true,
    width: 280,
  },
  {
    name: 'amount',
    isShow: true,
    isPermanent: true,
    type: TABLE_TYPES.CURRENCY,
    width: 150,
  },
  {
    name: 'cycle',
    isShow: true,
    isPermanent: true,
    type: TABLE_TYPES.CYCLE,
    width: 150,
  },
  {
    name: 'nextBilling',
    isShow: false,
    type: TABLE_TYPES.DATE,
  },
  {
    name: 'startDate',
    isShow: false,
    type: TABLE_TYPES.DATE,
  },
  {
    ...statusCellOptions,
  },
];

export const statuses = [
  { value: 'ALL' },
  { value: 'NEW' },
  { value: 'ACTIVE' },
  { value: 'EXPIRED' },
  { value: 'CANCELLED' },
];

export const filters = reactive([
  {
    label: 'Date Range',
    type: 'DATE_RANGE',
    field: ['dateFrom', 'dateTo'],
    modelValue: ref([]),
  },
]);

export const cycleData = [
  {
    text: 'Weekly',
    value: 'WEEKLY',
  },
  {
    text: 'Monthly',
    value: 'MONTHLY',
  },
  {
    text: 'Quarterly',
    value: 'QUARTERLY',
  },
  {
    text: 'Semi-Annually',
    value: 'SEMI_ANNUALLY',
  },
  {
    text: 'Annually',
    value: 'ANNUALLY',
  },
];

export const periods = [
  { text: 'Start Now', value: 'NOW' },
  { text: 'Schedule', value: 'SCHEDULE' },
];
