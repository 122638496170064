<template>
  <div v-if="data.length" class="data">
    <div v-for="(item, index) in data" :key="item._id" class="row">
      <AppText
        class="cell"
        variant="div"
        opacity="0.4"
      >
        {{ data.length - index }}
      </AppText>

      <div class="cell">
        <DotSeparator color="#cccccc" style="margin-top: 6px;" />
      </div>

      <div class="cell">
        <DateFormatter
          style="white-space: nowrap;"
          :format="dateTimeFormat"
          :date="item.createdAt"
          variant="div"
          size="13px"
          opacity="0.4"
          mb="6px"
        />

        <TooltipCopy is-icon-hidden>
          <AppText variant="div">
            {{ item.uuid }}
          </AppText>
        </TooltipCopy>
      </div>

      <div class="cell">
        <StatusBadge :item="item" />
      </div>
    </div>
  </div>

  <NoData v-else class="no-data">
    <template #title>
      {{ t('merchantSubscriptions.subscriptionInfoModal.label.noHistory') }}
    </template>
  </NoData>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import NoData from '@/components/MainTable/NoData.vue';

import { useProfile } from '@/composables/useProfile';

export default defineComponent({
  name: 'SubscriptionPayments',
  components: {
    DateFormatter: defineAsyncComponent(() => import('@/components/Helpers/DateFormatter.vue')),
    DotSeparator,
    TooltipCopy,
    StatusBadge,
    NoData,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { dateTimeFormat } = useProfile();

    return { dateTimeFormat };
  },
});
</script>

<style scoped lang="scss">
.data {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: repeat(auto-fill, 60px);

  .row {
    display: contents;

    .cell {
      padding: 12px 0;
      border-bottom: 1px solid var(--color-black-01);

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 16px;
      }
    }
  }
}

.no-data {
  min-height: 459px;
  padding: 0px 160px;
}
</style>
