<template>
  <div class="lock" :class="{ loaded: isLoaded }">
    <img alt="" :src="subscriptionBg" @load="isLoaded = true">
    <div class="content">
      <AppText
        variant="div"
        size="27px"
        align="center"
        class="font-medium"
        mb="26px"
      >
        {{ t('servicePage.subscriptions.title') }}
      </AppText>
      <AppText
        variant="div"
        size="17px"
        align="center"
        :line-height="1.5"
        mb="32px"
        style="max-width: 440px;"
      >
        {{ t('servicePage.subscriptions.description') }}
      </AppText>

      <FButton @click="onClick">
        {{ t('servicePage.subscriptions.button') }}
      </FButton>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';

import { emitter } from '@/composables/useBus';
import subscriptionBg from '@/assets/images/subscriptions_bg.svg';
import { getBillingInfo } from '@/api/merchant';

export default {
  name: 'LockSubscriptionTable',
  setup() {
    const isLoaded = ref(false);
    const billingInfo = ref({});

    const onClick = () => {
      emitter.emit('show-plan-selector', billingInfo.value);
    };

    onBeforeMount(async () => {
      const { data } = await getBillingInfo();
      billingInfo.value = data;
    });

    return {
      subscriptionBg,
      onClick,
      isLoaded,
    };
  },
};
</script>

<style lang="scss" scoped>
.lock {
  width: 100%;
  min-height: calc(100vh - 80px);
  background: #F7F7F7;
  padding: 60px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;

  &.loaded {
    opacity: 1;
    @include transition-base(opacity);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -160px;
  }
}
</style>
