<template>
  <div class="note" :class="[type]">
    <div class="icon-wrap">
      <AppIcon name="clock" :opacity="0.4" size="18px" is-img-tag />
    </div>
    <AppText variant="div" :line-height="1.5" mt="10px" mb="10px">
      <slot />
    </AppText>
  </div>
</template>

<script>
export default {
  name: 'DetailsNote',
  props: {
    type: {
      type: String,
      default: 'info',
      validator: (value) => ['info', 'warning', 'new'].includes(value),
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.note {
  min-height: 40px;
  display: grid;
  grid-template-columns: 18px auto;
  grid-gap: 24px;
  align-items: center;

  padding: 0 16px;
  border-radius: 8px;

  &.info {
    border: 1px solid var(--color-black-01);
  }

  &.warning {
    background: #FBF3EC;
  }

  &.new {
    background: #F7F7F7;
  }

  .icon-wrap {
    height: 24px;
    @include flex-center;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: calc(18px + 11px);
      height: 24px;
      width: 1px;
      background-color: var(--color-black-01);
    }
  }
}
</style>
