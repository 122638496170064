<template>
  <SmoothModal
    v-model="isModalOpen"
    :has-header="false"
    width="660px"
    :has-header-divider="false"
    :has-body-paddings="false"
    :has-transition-height="false"
    @close="onModalClose"
  >
    <template #default="{ height, hasTransitionHeight }">
      <SimpleTransition>
        <div v-if="!shouldBeCancelled">
          <div class="subscription-header">
            <AppText size="18px" class="font-medium">
              {{ t('merchantSubscriptions.subscriptionInfoModal.title') }}
            </AppText>

            <DashboardModalDescription
              :uuid="subscription?.clientSubscriptionId"
              :status="subscription?.status"
              :cancellation-reason="subscription?.cancellationReason"
              @update="onSubscriptionUpdate"
            />
          </div>

          <TabsContainer
            v-model="currentTab"
            :options="tabs"
            :other-tab="{
              name: 'payments',
              value: subscription?.paymentsCount,
            }"
          >
            <div style="padding: 0 40px">
              <SubscriptionDetails
                v-if="currentTab === tabs[0].value"
                :data="subscription"
                :payments-statuses="subscriptionPaymentsStatuses"
                @cancel="shouldBeCancelled = true"
                @close-modal="isModalOpen = false"
              />
              <SubscriptionPayments
                v-if="currentTab === tabs[1].value"
                :data="subscriptionPayments"
                :style="!hasTransitionHeight ? `max-height: ${height - 60}px` : ''"
              />
            </div>
          </TabsContainer>
        </div>
        <CancelSubscription
          v-if="shouldBeCancelled"
          :is-loading="isLoading"
          @remove="onCancelSubscription"
          @keep="shouldBeCancelled = false"
        />
      </SimpleTransition>
    </template>
  </SmoothModal>
</template>

<script>
import { computed, ref, watch } from 'vue';

import DashboardModalDescription from '@/components/DashboardModalDescription.vue';
import SimpleTransition from '@/components/Animation/SimpleTransition.vue';
import SmoothModal from '@/components/SmoothModal';
import TabsContainer from '@/components/Containers/TabsContainer.vue';

import { emitter } from '@/composables/useBus';
import { useModel } from '@/composables/useModel';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';
import { i18n } from '@/plugins/localization';

import { cancelSubscription, getPaymentsForSubscription } from '../api';

import CancelSubscription from './CancelSubscription.vue';
import SubscriptionDetails from './SubscriptionDetails.vue';
import SubscriptionPayments from './SubscriptionPayments.vue';

export default {
  name: 'SubscriptionModal',
  components: {
    SmoothModal,
    TabsContainer,
    CancelSubscription,
    DashboardModalDescription,
    SimpleTransition,

    SubscriptionDetails,
    SubscriptionPayments,
  },
  props: {
    modelValue: {
      type: Boolean,
      deafult: false,
    },
    subscription: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  emits: ['update:modelValue', 'update-data'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const { value: isModalOpen } = useModel(props, emit);
    const shouldBeCancelled = ref(false);

    const tabs = computed(() => [
      { text: t('merchantSubscriptions.subscriptionInfoModal.tab.overview'), value: 'overview' },
      { text: t('merchantSubscriptions.subscriptionInfoModal.tab.payments'), value: 'payments' },
    ]);
    const currentTab = ref(tabs.value[0].value);

    const isLoading = ref(false);
    const onCancelSubscription = async () => {
      isLoading.value = true;
      const { isSuccess } = await cancelSubscription({
        subscriptionId: props.subscription?.id,
      });
      if (isSuccess) {
        isModalOpen.value = false;
        shouldBeCancelled.value = false;
        emitter.emit('update-subscriptions-page');
        isLoading.value = false;
      }
    };

    const subscriptionPayments = ref([]);
    watch(computed(() => props.modelValue), () => {
      if (isModalOpen.value) {
        getPaymentsForSubscription({ subscriptionId: props.subscription?.id }).then(({ data }) => {
          subscriptionPayments.value = data;
        });
      }
    });
    const subscriptionPaymentsStatuses = computed(() => subscriptionPayments.value.map((p) => p.status));

    const onSubscriptionUpdate = () => {
      getPaymentsForSubscription({ subscriptionId: props.subscription?.id, isFreshResponse: true }).then(({ data }) => {
        subscriptionPayments.value = data;
      });
      emit('update-data');
    };

    const onModalClose = () => {
      shouldBeCancelled.value = false;
      currentTab.value = tabs.value[0].value;
    };

    useKeyboardEvent(keys.BACKQUOTE, () => { isModalOpen.value = false; });

    return {
      isModalOpen,
      onModalClose,
      tabs,
      currentTab,
      isLoading,

      subscriptionPayments,
      subscriptionPaymentsStatuses,

      shouldBeCancelled,

      onCancelSubscription,
      onSubscriptionUpdate,
    };
  },
};
</script>

<style lang="scss" scoped>
.subscription-header {
  padding: 30px 40px 0 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
}
</style>
