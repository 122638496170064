<template>
  <div class="subscription-details">
    <div class="subscription-title">
      <AppText size="17px" class="font-medium">
        {{ data.title }}
      </AppText>
    </div>
    <div class="d-flex justify-content-between">
      <TooltipCopy is-icon-hidden>
        <CurrencyFormatter
          :value="data.amount"
          :name="data.currency"
          is-suffix
          size="28px"
          class="font-medium"
        />
      </TooltipCopy>
      <div class="d-flex align-items-center">
        <AppIcon
          size="18px"
          name="calendar"
          is-img-tag
          style="margin-right: 8px;"
        />
        <AppText class="font-medium" style="margin-top: 1px;">
          {{ t('common.due') }} {{ t(`cycle.${data.cycle}`) }}
        </AppText>
      </div>
    </div>
    <div class="d-flex justify-content-between amount-details">
      <div class="d-flex flex-column" style="margin-right: 35px;">
        <AppText pb="10px" opacity="0.3">
          {{ t('merchantSubscriptions.subscriptionInfoModal.label.createdAt') }}
        </AppText>
        <DateFormatter
          :date="data.createdAt"
          :format="dateTimeFormat"
          class="font-medium"
        />
      </div>
      <div v-if="data.status === 'CANCELLED'" class="d-flex flex-column">
        <AppText pb="10px" opacity="0.3">
          {{ t('merchantSubscriptions.subscriptionInfoModal.label.cancelledAt') }}
        </AppText>
        <DateFormatter
          :date="data.cancelledAt"
          :format="dateTimeFormat"
          class="font-medium"
        />
      </div>
      <div v-else-if="data.status === 'EXPIRED'" class="d-flex flex-column">
        <AppText pb="10px" opacity="0.3">
          {{ t('merchantSubscriptions.subscriptionInfoModal.label.expiredAt') }}
        </AppText>
        <DateFormatter
          :date="data.expiredAt"
          :format="dateTimeFormat"
          class="font-medium"
        />
      </div>
      <FSpacer />
      <div class="d-flex flex-column align-items-end">
        <AppText pb="10px" opacity="0.3">
          {{ t('merchantSubscriptions.subscriptionInfoModal.label.recieved') }}
        </AppText>
        <TooltipCopy is-icon-hidden>
          <CurrencyFormatter
            :value="data.totalReceived"
            :name="data.currency"
            is-suffix
            class="font-medium"
          />
        </TooltipCopy>
      </div>
    </div>

    <div class="client-details">
      <AppText
        size="12px"
        type="uppercase"
        class="font-medium"
        opacity="0.4"
      >
        {{ t('merchantSubscriptions.subscriptionInfoModal.label.client') }}
      </AppText>
      <div class="d-flex align-items-center" style="margin-top: 25px;">
        <AppIcon
          is-img-tag
          name="user-sholders"
          size="16px"
          style="margin-right: 16px;"
        />
        <UserInfoLine
          style="margin-top: 1px;"
          :client="{
            name: data?.clientName,
            email: data?.clientEmail,
            shortId: data?.clientShortId,
          }"
          @run-search="runSearch"
        />
      </div>
    </div>

    <DetailsNote v-if="data.status === 'NEW'" class="subscription-note" type="new">
      <i18n-t keypath="merchantSubscriptions.subscriptionInfoModal.noteNew">
        <template #date>
          <DateDurationWithNow :date="expireDate" no-styles />
        </template>
      </i18n-t>
    </DetailsNote>

    <template v-if="data.status === 'ACTIVE'">
      <DetailsNote v-if="data?.failedAttempt?.reason === 'QUOTA'" class="subscription-note" type="warning">
        <i18n-t keypath="merchantSubscriptions.subscriptionInfoModal.noteFailedReasonQuota">
          <template #date>
            <DateDurationWithNow :date="expireDate" no-styles />
          </template>
        </i18n-t>
      </DetailsNote>
      <DetailsNote v-else-if="data?.failedAttempt?.reason === 'WALLET'" class="subscription-note" type="warning">
        <i18n-t keypath="merchantSubscriptions.subscriptionInfoModal.noteFailedReasonWallet">
          <template #date>
            <DateDurationWithNow :date="expireDate" no-styles />
          </template>
        </i18n-t>
      </DetailsNote>
      <DetailsNote v-else class="subscription-note" type="info">
        <i18n-t keypath="merchantSubscriptions.subscriptionInfoModal.noteActive">
          <template #date>
            <DateDurationWithNow :date="expireDate" no-styles />
          </template>
        </i18n-t>
      </DetailsNote>
    </template>

    <div v-if="canSubscriptionToBeCancelled">
      <FButton type="black-danger" @click="$emit('cancel')">
        <AppIcon name="ban-block-slash" size="18px" />
        {{ t('merchantSubscriptions.subscriptionInfoModal.button.cancel') }}
      </FButton>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import dayjs from 'dayjs';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import DateDurationWithNow from '@/components/DateDurationWithNow.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import UserInfoLine from '@/components/Payment/UserInfoLine.vue';

import { checkPaymentsInProgress } from '@/api/merchant';
import { emitter } from '@/composables/useBus';
import { useProfile } from '@/composables/useProfile';

import DetailsNote from './DetailsNote.vue';

export default defineComponent({
  name: 'SubscriptionDetails',
  components: {
    CurrencyFormatter,
    DateFormatter,
    UserInfoLine,
    TooltipCopy,
    DetailsNote,
    DateDurationWithNow,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    paymentsStatuses: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['cancel'],
  setup(props) {
    const { dateTimeFormat } = useProfile();

    const expireDate = computed(() => {
      if (props.data?.status === 'NEW') {
        return dayjs(props.data?.createdAt).add(24, 'hour').toISOString();
      }

      if (props.data?.status === 'ACTIVE' && props.data?.failedAttempt) {
        return props.data?.failedAttempt?.nextAttempt;
      }

      if (props.data?.status === 'ACTIVE') {
        return props.data?.nextBilling;
      }

      return null;
    });

    const canSubscriptionToBeCancelled = computed(() => {
      const isSubscriptionActive = ['ACTIVE'].includes(props.data?.status) && !props.data?.failedAttempt;
      const arePaymentsInProgress = props.paymentsStatuses.some(checkPaymentsInProgress);

      return isSubscriptionActive && !arePaymentsInProgress;
    });

    const runSearch = (val) => {
      emitter.emit('run-search', val.toString());
      emitter.emit('close-modal');
    };

    return {
      dateTimeFormat,
      runSearch,
      expireDate,
      canSubscriptionToBeCancelled,
    };
  },
});
</script>

<style scoped lang="scss">
.subscription-details {
  padding: 40px 0;

  :deep(.subscription-note.info) {
    margin-bottom: 30px;
  }

  .subscription-title {
    padding: 0 0 20px;
    border-bottom: 1px solid var(--color-black-01);
    margin-bottom: 25px;
  }

  .amount-details {
    padding: 25px 0 33px;
    border-bottom: 1px solid var(--color-black-01);
  }

  .client-details {
    padding-top: 32px;
    padding-bottom: 40px;
  }
}
</style>
